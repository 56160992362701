<template>
    <Slide>
      <a href="/courses" style="padding-left: 0;">
         <i class="el-icon-collection-tag"></i>
        <span>Матрица судьбы</span>
      </a>
      <a href="/course/1">
        <span>Для новичков</span>
      </a>
      <a href="/course/2">
        <span>Для продвинутых</span>
      </a>
      <a href="#" class="disable">
        <span>Для экспертов</span>
      </a>
      <a href="/course/4">
        <span>Для проводников</span>
      </a>
      <a href="/classes" style="padding-left: 0px;margin-top: 2.5em;">
        <i class="el-icon-collection-tag"></i>
        <span>Мастер-классы</span>
      </a>
      <a href="/class/1">
        <span>Продвижение</span>
      </a>
      <a href="/class/2" class="disable">
        <span>Женские архетипы</span>
      </a>
      <a href="/lilas" style="padding-left: 0px;margin-top: 2.5em;">
        <i class="el-icon-collection-tag"></i>
        <span>Лила чакра</span>
      </a>
      <a href="/lila/1">
        <span>Обучение мастеров</span>
      </a>
<!--      <a href="/class/2" class="disable">-->
<!--        <span>База игр</span>-->
<!--      </a>-->
      <a href="/masters">
        <span>Список мастеров</span>
      </a>
    </Slide>
</template>

<script>
import { Slide } from 'vue3-burger-menu'

export default {
    components: {
        Slide // Register your component
    }
}
</script>

<style>
   .bm-burger-button {
      position: fixed!important;
      width: 4%!important;
      height: 5%!important;
      left: 2.5%!important;
      top: 6%!important;
      cursor: pointer!important;
   }
   .line-style {
      position: absolute!important;
      height: 20%!important;
      left: 0!important;
      right: 0!important;
    }
   @media only screen and (max-width: 768px) {
      .bm-burger-button {
         width: 4%!important;
         height: 3%!important;
         left: 2.5%!important;
         top: 4%!important;
      }
      .line-style {
         position: absolute!important;
         height: 10%!important;
      }
   }
    .bm-burger-bars {
      background-color: #d3d3d6!important;
    }
    .cross-style {
      position: absolute!important;
      top: 12px!important;
      right: 2px!important;
      cursor: pointer!important;
    }
    .bm-cross {
      background: #bdc3c7!important;
    }
    .bm-cross-button {
      height: 24px!important;
      width: 24px!important;
    }
    .bm-menu {
      max-width: 250px!important;
      height: 100%!important; /* 100% Full-height */
      position: fixed!important; /* Stay in place */
      z-index: 1000!important; /* Stay on top */
      top: 0!important;
      left: 0!important;
      background-color: #5E597B!important;
      overflow-x: hidden!important; /* Disable horizontal scroll */
      padding-top: 40px!important; /* Place content 60px from the top */
      transition: 0.5s!important; /*0.5 second transition effect to slide in the sidenav*/
    }
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3)!important;
    }
    .bm-item-list {
      color: #b8b7ad!important;
      margin-left: 10%!important;
      font-family: 'Tenor Sans', sans-serif!important;
      font-weight: 400!important;
      font-style: normal!important;
      font-size: 16px!important;
    }
    .bm-item-list > * {
      display: flex!important;
      text-decoration: none!important;
      padding: 0.7em!important;
    }
    .bm-item-list > * > span {
      margin-left: 10px!important;
      font-weight: 700!important;
      color: white!important;
    }
    .disable span {
       color: #9e9e9f!important;
    }
    .disable span:hover {
      opacity: .25!important;
      cursor: not-allowed!important;
      background: none!important;
    }
</style>
